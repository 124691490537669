<template>
  <div ref="decorator" class="decorator" :class="{ isHorizontal, isSmallerThanLaptop }" :data-is-error="isError">
    <label v-if="label" class="decorator__label" :class="{ isHorizontal }">
      <Icon v-if="icon" :icon="icon" class="decorator__label__icon" />
      <div class="decorator__label__text">
        {{ label }}
      </div>
    </label>

    <div ref="content" class="decorator__content" :class="{ alignInputToEnd }">
      <slot :width="`${width}px`" :is-error="isError"></slot>

      <div v-if="isError && errorCaption" class="decorator__caption" :class="{ isError }">
        {{ errorCaption }}
      </div>

      <div v-if="caption" class="decorator__caption">
        {{ caption }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
defineProps<{
  label?: string;
  caption?: string;
  errorCaption?: string;
  isError?: boolean;
  icon?: string;
  isHorizontal?: boolean;
  alignInputToEnd?: boolean;
}>();

const decorator = ref<HTMLDivElement>();
const content = ref<HTMLDivElement>();

const { isSmallerThanLaptop } = useMedia();

/**
 * @deprecated use useZodValidation for form validation
 */
const scrollIntoView = function (args: ScrollIntoViewOptions = { behavior: "smooth" }) {
  decorator.value?.scrollIntoView(args);
};

const { width } = useElementSize(content);

defineExpose({ scrollIntoView });
</script>

<style scoped lang="scss">
.decorator {
  display: flex;
  flex-direction: column;
  gap: 12px;

  &.isHorizontal {
    display: grid;
    grid-template-columns: 1fr 2fr;
    align-items: center;

    &.isSmallerThanLaptop {
      display: flex;
      flex-direction: column;
    }
  }

  &__label {
    display: flex;
    gap: 8px;
    align-self: flex-start;

    &.isHorizontal {
      margin-top: 12px;
    }

    &__icon {
      height: 18px;
      width: 18px;
      color: var(--gray-2);
    }

    &__text {
      font-size: var(--size-14);
      font-weight: 500;
      line-height: 18px;
      color: var(--gray-2);

      &:first-child {
        margin-left: 8px;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 12px;
    overflow: hidden;
    padding: 4px; // To show outline when an input is focused
    margin: -4px;
  }

  &__caption {
    color: var(--gray-2);
    font-size: var(--size-14);

    &.isError {
      color: var(--red);
    }
  }

  .alignInputToEnd {
    margin-left: auto;
  }
}
</style>
